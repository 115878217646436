@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,700");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.custom-toggler.navbar-toggler {
    border-color: rgb(79, 131, 191);
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(79,131,191, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#box p.info {
    font-size: 1.3rem;
}

@media (max-width: 480px) {
    #box p.info {
        font-size: 1rem;
    }
}

#catering p.info {
    font-size: 1.3rem;
    color: #212529;
    text-align: left;
}

@media (max-width: 480px) {
    #catering p.info {
        font-size: 1rem;
    }
}

.left {
    text-align: left;
}

.right{
    text-align: right;
}
/* Style to change separator  */
.breadcrumb-item + .breadcrumb-item::before {
    content: "|";
}
